<div class="w-full flex flex-row mt-3">
  @if (showLoading) {
    <maximizer-loader type="legacy"></maximizer-loader>
  }

  @if (showSkeleton || loadingSyncConfig) {
    <div class="flex flex-col w-full mx-2">
      <maximizer-outlook-skeleton
        containerClasses="h-10 w-full rounded-md bg-neutral-20 px-10 mt-2"
      ></maximizer-outlook-skeleton>
    </div>
  }

  @if (showSaveEmailData) {
    <div class="flex flex-col">
      <!-- Save and Send Toggle -->
      <span
        kendoTooltip
        [tooltipTemplate]="linkToAppStoreTooltip"
        [tooltipWidth]="250"
        [callout]="false"
        position="top"
        [closable]="true"
        [showOn]="outlookSyncInstalled ? 'none' : 'click'"
      >
        <kendo-switch
          #switch
          [disabled]="showSkeleton || !outlookSyncInstalled"
          [(ngModel)]="saveToEmailSwitch"
          (valueChange)="handleSwitchValueChanged($event)"
          size="small"
          title=""
        >
        </kendo-switch>
      </span>
    </div>

    <ng-template #linkToAppStoreTooltip>
      <div class="flex flex-col gap-2 ml-2 offset-left-tooltip">
        <p class="subtitle-2">
          {{ 'outlook.entry-page.autoSave.newFeature' | translate }}
        </p>
        <p class="text-body">
          {{ 'outlook.entry-page.autoSave.installation' | translate }}
        </p>
        <p class="text-body">
          {{ 'outlook.entry-page.autoSave.installationNote' | translate }}
        </p>
        <div class="flex justify-end">
          <button
            kendoButton
            themeColor="primary"
            (click)="buildAppStoreFormRequest()"
          >
            {{ 'outlook.buttons.install' | translate }}
          </button>
        </div>
      </div>
    </ng-template>

    <div class="flex flex-col w-full ml-2">
      <kendo-label for="switch" class="w-full">{{
        'outlook.compose.multiselect.save-to' | translate
      }}</kendo-label>

      @if (!showSkeleton) {
        <!-- Recipients List -->
        <kendo-multiselecttree
          #multiselecttree
          kendoMultiSelectTreeExpandable
          kendoMultiSelectTreeSummaryTag
          class="w-full mt-2 compose-entry-multiselect"
          [disabled]="!saveToEmailSwitch || !outlookSyncInstalled"
          [placeholder]="
            'outlook.compose.multiselect.recipients-emptystate' | translate
          "
          [clearButton]="false"
          [value]="emailsToSaveInMaximizer"
          [popupSettings]="{
            height: 120,
            width: 220,
            popupClass: 'overflow-x-hidden',
          }"
          [checkableSettings]="{
            checkChildren: true,
            checkOnClick: true,
          }"
          [isNodeExpanded]="expandParentNode"
          [itemDisabled]="isCheckboxDisabled || !outlookSyncInstalled"
          (valueChange)="handleMultiselectTreeChange($event)"
          [kendoMultiSelectTreeHierarchyBinding]="emailListForMultiselect"
          textField="displayName"
          valueField="localId"
          childrenField="items"
        >
          <ng-template
            kendoMultiSelectTreeNodeTemplate
            class=""
            let-dataItem
            let-index="index"
          >
            <div
              class="max-[360px]:max-w-[140px] max-[420px]:max-w-[240px] text-ellipsis overflow-x-hidden cursor-pointer"
            >
              <span
                *ngIf="
                  parentItems.includes(dataItem.localId);
                  else showEntryListItem
                "
                >{{ dataItem.displayName }}</span
              >
              <ng-template #showEntryListItem
                ><maximizer-outlook-entry-list-item
                  [dataItem]="dataItem"
                ></maximizer-outlook-entry-list-item
              ></ng-template>
            </div>
          </ng-template>

          <ng-template
            kendoMultiSelectTreeGroupTagTemplate
            let-dataItems
            class="flex flex-row w-full"
          >
            {{
              'outlook.compose.multiselect.recipients-number'
                | translate
                  : {
                      amount: countSelectedItemsIgnoringParent(dataItems),
                      total: saveEmailToEntriesTotal,
                    }
            }}
          </ng-template>
        </kendo-multiselecttree>
      }
    </div>
  } @else if (!loadingSyncConfig && !showSkeleton) {
    <div class="w-full flex flex-col">
      @if (!isEmailSyncFeatureFlagEnabled && !isOutlookLegacyDesktop) {
        <p>{{ 'outlook.compose.email-sync-upcoming' | translate }}</p>
      } @else if (isOutlookLegacyDesktop) {
        <div class="rounded-sm p-3 mb-2 bg-[#FFFDD4] text-body">
          {{ 'outlook.compose.classic-unavailable' | translate }}
        </div>
        <p class="text-body">
          {{
            (isEmailSyncFeatureFlagEnabled
              ? 'outlook.compose.classic-available-alternative'
              : 'outlook.compose.email-sync-upcoming'
            ) | translate
          }}
        </p>
      }
    </div>
  }
</div>
