import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class EncryptionService {
  // SHA256 is a one way hashing process (cannot decrypt) - Required for OAuth2.0
  oauthHashCodeVerifier(codeVerifier: string): string {
    const hash = CryptoJS.SHA256(codeVerifier);
    const base64 = CryptoJS.enc.Base64.stringify(hash);
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=*$/, '');
  }

  encrypt(value: string, password: string): string {
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    const key = CryptoJS.PBKDF2(password, salt, {
      keySize: 256 / 32,
      iterations: 1000,
    });
    const iv = CryptoJS.lib.WordArray.random(128 / 8);

    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value),
      key,
      {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    );

    return (
      CryptoJS.enc.Hex.stringify(salt) +
      CryptoJS.enc.Hex.stringify(iv) +
      encrypted.toString()
    );
  }

  decrypt(value: string, password: string): string {
    try {
      const salt = CryptoJS.enc.Hex.parse(value.substring(0, 32));
      const iv = CryptoJS.enc.Hex.parse(value.substring(32, 64));
      const encrypted = value.substring(64);

      const key = CryptoJS.PBKDF2(password, salt, {
        keySize: 256 / 32,
        iterations: 1000,
      });

      const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Decrypt error', error);
      return '';
    }
  }
}
